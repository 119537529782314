import React, { useState, useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';


// Reusable Feature Section Component
const FeatureSection = ({
  data,
  activeIndex,
  setActiveIndex,
  title,
  showToggle,
  showButton,
  toggleData,
  setToggleData,
}) => {
  const [progress, setProgress] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (isPaused) return;

    setProgress(0);
    const progressInterval = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? 100 : prev + 1));
    }, 46);

    const switchSection = setTimeout(() => {
      setActiveIndex((prev) => (prev + 1) % data.length);
      setProgress(0);
    }, 4615);

    return () => {
      clearTimeout(switchSection);
      clearInterval(progressInterval);
    };
  }, [activeIndex, isPaused]);

  const handleClick = (index) => {
    setIsPaused(true);
    setActiveIndex(index);
    setProgress(0);
    setTimeout(() => setIsPaused(false), 100);
  };

  return (
    <div className="third">
      <div className="row">
        {/* Left Side Image */}
        <div className="col-lg-6 input">
          <Fade key={activeIndex} direction="right">
            <img src={data[activeIndex].image} alt="" width={350} />
          </Fade>
        </div>

        {/* Right Side Content */}
        <div className="col-lg-6">
          {/* Only show toggle buttons in the second section */}
          {showToggle && (
            <div className="toggle-head d-flex">
              <p
                className={`toggle-option ${
                  toggleData === "integration" ? "active" : ""
                }`}
                onClick={() => setToggleData("integration")}
              >
                INTEGRATION
              </p>
              <p
                className={`toggle-option ${
                  toggleData === "technology" ? "active" : ""
                }`}
                onClick={() => setToggleData("technology")}
              >
                TECHNOLOGY
              </p>
            </div>
          )}

          <div className="input-text">
            <Fade direction="down">
              <h1>{title}</h1>
            </Fade>
            
            <div className="info">
              {data.map((item, index) => (
                <Fade direction="left">
                <div
                  key={index}
                  className={`section ${activeIndex === index ? "active" : ""}`}
                >
                  <p
                    className={`head ${activeIndex === index ? "active" : ""}`}
                    onClick={() => handleClick(index)}
                  >
                    {item.title}
                  </p>
                  <div
                    className={`progress-bar ${
                      activeIndex === index ? "active" : ""
                    }`}
                    style={{
                      width: `${activeIndex === index ? progress : 0}%`,
                    }}
                  ></div>
                  {activeIndex === index && (
                    <p className="body">{item.content}</p>
                  )}
                </div>
                </Fade>
              ))}

              {/* Only show button in the first section */}
              {showButton && (
                <Fade direction="up">
                  <div className="btn">
                    <a href="https://shopify.com">
                      <button className="cta2">
                        <img
                          src="/assets/images/kwikpass/shopw.png"
                          alt="Shopify Logo"
                          width={20}
                        />
                        Get It On Shopify
                      </button>
                    </a>
                  </div>
                </Fade>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SectionTwo = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [secondIndex, setSecondIndex] = useState(0);
  const [toggleData, setToggleData] = useState("integration");


 const isMobile = useMediaQuery({ maxWidth: 768 });


  const sections = [
    {
      title: "Seamless Phone-First Login",
      content:
        "Allow customers to quickly log in via OTP—no password needed—for a frictionless and secure shopping experience.",
      image: "/assets/images/kwikpass/input.png",
    },
    {
      title: "Smart Marketing Pop-Ups",
      content:
        "Engage customers with interactive pop-ups triggered based on their behavior, displaying personalized offers at the right moment.",
      image: "/assets/images/kwikpass/input.png",
    },
    {
      title: "Zero-click Anonymous User Identification",
      content:
        "Identify up to 25% anonymous visitors with 99.99% accuracy using advanced fingerprinting and GoKwik's proprietary user matching algorithms—powered by the most secure website visitor tracking software.",
      image: "/assets/images/kwikpass/input.png",
    },
    {
      title: "Data Capture at Every Touchpoint",
      content:
        "Track where users drop off and retarget them effectively. Collect emails, DOB, and more to personalize campaigns with insights from Kwik Pass visitor tracking app on Shopify.",
      image: "/assets/images/kwikpass/input.png",
    },
    {
      title: "Customizable Consent Management",
      content:
        "Capture and relay customer consent to Shopify seamlessly. Choose between opt-in, opt-out, or compulsory consent—putting you in control of data privacy while ensuring compliance and transparency.",
      image: "/assets/images/kwikpass/input.png",
    },
    {
      title: "Compare Your Stats with Industry Benchmarks",
      content:
        "Analyze how your data collection and engagement rates compare to industry standards to optimize performance and strategy.",
      image: "/assets/images/kwikpass/input.png",
    },
    {
      title: "Integrate & Retarget with KwikEngage",
      content:
        "Combine with Kwik Engage to launch targeted WhatsApp, SMS, and email campaigns that recover lost sales by targeting anonymous users.",
      image: "/assets/images/kwikpass/input.png",
    },
  ];


  const second = [
    {
      title: "Recognize & Retarget Instantly",
      content:
        "Identify anonymous & returning visitors for hyper-personalized engagement.",
      image: "/assets/images/kwikpass/input.png",
    },
    {
      title: "Automate Retargeting",
      content:
        "Trigger WhatsApp, SMS & email campaigns in real-time based on user actions.",
      image: "/assets/images/kwikpass/input.png",
    },
    {
      title: "Recover Abandoned Carts",
      content:
        "Instantly reach out with targeted offers when a user drops off.",
      image: "/assets/images/kwikpass/input.png",
    },
    {
      title: "Enhance Customer Retention",
      content:
        "Use deep insights to re-engage customers and drive repeat purchases.",
      image: "/assets/images/kwikpass/input.png",
    },
    {
      title: "Drive Higher Conversions",
      content:
        "Leverage AI-driven segmentation and personalized messaging for better sales.",
      image: "/assets/images/kwikpass/input.png",
    },
  ];

  const tech = [
    {
      title: "Advanced User Recognition",
      content: "Fingerprint tracking & intelligent identification.",
      image: "/assets/images/kwikpass/input.png",
    },
    {
      title: "	Analytics & Insights",
      content:
        " Google Analytics & Meta integration for deep performance tracking.",
      image: "/assets/images/kwikpass/input.png",
    },
    {
      title: "	Seamless Data Sync",
      content: "Direct integrations with leading CRM & marketing platforms.",
      image: "/assets/images/kwikpass/input.png",
    },
    {
      title: "	Rewards & Loyalty",
      content: "Collaborate with reward partners to drive repeat purchases.",
      image: "/assets/images/kwikpass/input.png",
    },
    {
      title: "	Universal Compatibility",
      content:
        "Integrate with custom-built stores, Shopify stores, and custom FE Shopify stores with Shopify customer data platform capabilities.",
      image: "/assets/images/kwikpass/input.png",
    },
  ];


  const imagePaths = [
    "/assets/images/kwikpass/group1.png",
    "/assets/images/kwikpass/group2.png",
    "/assets/images/kwikpass/group3.png",
    "/assets/images/kwikpass/group4.png",
    "/assets/images/kwikpass/group5.png",
  ];



  
  const settingsmob = {
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "40px",
    arrows: false, // Hide default arrows
    dots: false,   // Hide navigation dots
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
    ],
  };


  return (
    <div className="section-2">
      <div className="container-fluid">
        <Fade direction="down">
          <div className="top-text">
            <h1>GoKwik Network Effect</h1>

            {
              isMobile ? 
              <>
               <p>
              Leveraging the deepest pool of shoppers to build industry-best
              solutions  with AI-powered website visitor tracking tools
            </p>
              </>:
                <p>
                Leveraging the deepest pool of shoppers to build industry-best
                solutions <br/> with AI-powered website visitor tracking tools
              </p>
            }
          
          </div>
        </Fade>

        {/* Key Metrics */}
        <Fade direction="up">
          <div className="row d-flex justify-content-center mt-5">
            <div className="details">
              <div className="accurate d-flex same">
                <h1>25%</h1>
                {
                  isMobile ? <p >
                  of anonymous user
                  sessions identified
                  </p> :
                   <p className="mobile">
                   of anonymous user <br/>
                   sessions identified
                   </p>
                }
               
              </div>
              <div className="line"></div>
              <div className="anonymous d-flex same">
                <h1>5-10X</h1>
                <p>
                increase <br/>
                in ROAS
                </p>
              </div>
              <div className="line"></div>
              <div className="active d-flex same">
                <h1>130Mn+</h1>
                <p>
                Shoppers on <br/>
                GoKwik network
                </p>
              </div>
            </div>
          </div>
        </Fade>

        <div className="container-fulid fourth">
          <Fade direction="down">
            <div className="top-text growth">
              <h1>Powering E-commerce Everywhere</h1>
              <p>
              Wherever You Sell, We Help You Identify & Engage More Shoppers.
              </p>
            </div>
 
          </Fade>
        </div>

        <div className="container-fluid fourth d-flex justify-content-center position-relative power-div">
      {/* Lines for the cross and diagonals */}
      <div className="cross-lines ">
        <div className="vertical-line">
                  
            <div className=" mr-3 shopify-div  position-absolute ">
                <img src="/assets/images/kwikpass/publicApp.png" className="position-absolute shopify-sec-img" alt="" />
                <h3 className="position-absolute shopify-sec-head ">Shopify Public App</h3>
                <div className="position-absolute shopify-sec-text ">One-click integration for Shopify merchants to enable customer identification and engagement.</div>
            </div>

            <div className=" mr-3 position-absolute store-div">
                <img src="/assets/images/kwikpass/store.png" className="position-absolute store-sec-img" alt="" />
                <h3 className="position-absolute store-sec-head d-inline ">Shopify Custom Stores</h3>
                <div className="position-absolute store-sec-text ">Supports tailored Shopify implementations, ensuring seamless login and pop-up functionality.</div>
            </div>    

            
            <div className=" mr-3 position-absolute appmaker-div">
                <img src="/assets/images/kwikpass/appmaker.png" className="position-absolute appmaker-sec-img" alt="" />
                <h3 className="position-absolute appmaker-sec-head d-inline ">Appmaker & Appbrew</h3>
                <div className="position-absolute appmaker-sec-text ">Enables brands using these platforms to enhance user identification and marketing pop-ups.</div>
            </div>    

            <div className=" mr-3 position-absolute customweb-div">
                <img src="/assets/images/kwikpass/customweb.png" className="position-absolute customweb-sec-img" alt="" />
                <h3 className="position-absolute customweb-sec-head d-inline ">Custom-Built Websites</h3>
                <div className="position-absolute customweb-sec-text ">Easily integrates with any proprietary website to power secure user authentication and engagement.</div>
            </div>   

             <div className="mr-3 position-absolute mobileApp-div">
                <img src="/assets/images/kwikpass/mobileApp.png" className="position-absolute mobileApp-sec-img" alt="" />
                <h3 className="position-absolute mobileApp-sec-head d-inline ">Android Mobile App</h3>
                <div className="position-absolute mobileApp-sec-text ">Extends seamless login and engagement capabilities to mobile-first businesses.</div>
            </div>  

          </div>  {/* Extended Top Line */}
        <div className="diagonal-line right-top"></div>
        <div className="diagonal-line right-bottom"></div>
      </div>

      {/* KwikPass Box */}
      <Fade direction="down">
        <div className="powering-details mt-5 mx-5">
          <h1>Kwik</h1>
          <img src="/assets/images/kwikpass/thunderLogo.png" alt="Thunder Logo" />
          <h1>Pass</h1>
        </div>
      </Fade>
    </div>

    <div className="container-fluid fourth d-flex justify-content-center align-items-center">
  <Fade direction="down">
    <div className="top-text  text-center">
      <Fade direction="right">
        <div className="btn">
          <a href="../contact">
            <button className="cta">Book a Demo</button>
          </a>
          <a href="https://shopify.com">
            <button className="cta2 text-white">
              <img
                src="/assets/images/kwikpass/shop.png"
                alt="Shopify Logo"
                width={20}
              />
              Get It On Shopify
            </button>
          </a>
        </div>
      </Fade>
    </div>
  </Fade>
</div>

        <div className="container-fulid fourth">
          <Fade direction="down">
            <div className="top-text growth">
              <h1>Identify | Engage | Convert  <br/>
              The Smarter Way to Recognize Every Shopper</h1>
              <p className="mt-4">
              With Kwik Pass, the most advanced website visitor tracking app, every click counts toward higher <br/> growth.
              </p>
            </div>
           
          </Fade>
        </div>

        {
            isMobile ? 
           ( <div className="d-flex justify-content-center mt-5 mb-5">
            <Slider {...settingsmob}>
              {imagePaths.map((imagePath, index) => (
                <div key={index}>
                  <img src={imagePath} className="img-fluid" alt={`group${index + 1}`} />
                </div>
              ))}
            </Slider>
          </div> ):( <div className="d-flex justify-content-center mt-5" style={{marginBottom:"80px"}}>
                      <img src="/assets/images/kwikpass/group1.png" className="img-fluid" alt="group1" />
                  </div>)

        }

      </div>
    </div>
  );
};
