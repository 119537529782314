import React from 'react';
import { Fade } from "react-awesome-reveal";
import { useMediaQuery } from 'react-responsive';
const PowerDetails = [
  {
    heading: "Recognize & Retarget Instantly",
    content: "Experiment with different pop-up variations to see which drives the best results."
  },
  {
    heading: "Automate Retargeting",
    content: "Trigger WhatsApp, SMS & email campaigns in real-time based on user actions."
  },
  {
    heading: "Recover Abandoned Carts",
    content: "Instantly reach out with targeted offers when a user drops off."
  },
  {
    heading: "Enhance Customer Retention",
    content: "Use deep insights to re-engage customers and drive repeat purchases."
  },
  {
    heading: "Drive Higher Conversions",
    content: "Leverage AI-driven segmentation and personalized messaging for better sales."
  }
];

export const SectionFour = () => {

  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className='section-4'>
      <div className="container-fluid">
        <div className="sec4-heading">
          <h1>The Power Couple: KwikPass and Kwik Engage!</h1>
        </div>
        <p className='pb-5' style={{fontWeight:"100"}}>
          Recognize every shopper, re-engage them across channels, and unlock new revenue streams with <br />
          automated retargeting.
        </p>
        <div className='row justify-content-center' style={isMobile ? {} : {marginLeft: "200px"}}
>
          <div className="col-lg-6 col-12">
            {PowerDetails.map((item, index) => (
              <div key={index} className="mb-3">
                <Fade direction="left">
                  <div className="sec-4head">{item.heading}</div>
                  <div className="sec-4content">{item.content}</div>
                </Fade>
              </div>
            ))}
            <Fade direction="left">
              <div className="btn">
                <a href="../contact">
                  <button className="cta">Know More</button>
                </a>
              </div>
            </Fade>
          </div>

          <div className="col-lg-6 col-12 d-flex power-vid">
            <Fade direction="right">
              <div>
                <video 
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{ height: "auto", width: "268px",marginTop:"30px" }}
                >
                  <source src="/assets/images/kwikpass/pow_coup.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};
