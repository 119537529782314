
import React, { useState } from 'react';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { start } from 'repl';

const FAQData = [
    {
        questNo:1,
        quest:"How does KwikPass identify anonymous users?",
        response:"We use advanced fingerprinting combined with phone-first OTP authentication to recognize visitors instantly—even without traditional logins."
    },
    {
        questNo:2,
        quest:"Can KwikPass integrate with my e-commerce platform?",
        response:"Yes, KwikPass is designed to work seamlessly with Shopify and custom e-commerce solutions."
    },
    {
        questNo:3,
        quest:"What are smart marketing pop-ups?",
        response:"They are interactive, personalized pop-ups that appear as soon as visitors land on your site, offering discounts and capturing key customer data."
    },
    {
        questNo:4,
        quest:"How does the integration with KwikEngage benefit my business?",
        response:"It enables targeted retargeting campaigns across multiple channels (WhatsApp, SMS, Email), leading to higher conversions and repeat sales."
    },
    {
        questNo:5,
        quest:"Is customer data secure with KwikPass?",
        response:"Absolutely. We adhere to industry-best security practices to ensure that your customer data remains fully protected.We are PCI DSS compliant."
    },
    {
        questNo:6,
        quest:"Does KwikPass create dummy emails to login users to Shopify?",
        response:"No, absolutely not. We have customer email associated with users in our network system. If we don’t find any, then we ask the user for their email upon login to Shopify."
    },
    {
        questNo:7,
        quest:"Can I run A/B test with the marketing popups?",
        response:"Absolutely. From March, 2025 this feature is available to merchants."
    },
    {
        questNo: 8,
        quest: "How does KwikPass identify anonymous users?",
        response: [
            {
                title: "Top-of-Funnel",
                content: [
                    "Use popups triggered by unique campaign URLs to welcome and validate new visitors.",
                    "Experiment Tip: A/B test different welcome messages and layouts."
                ]
            },
            {
                title: "Middle-of-Funnel",
                content: [
                    "For users who stick around (20+ seconds), show either a custom discount or an informational banner to boost conversion.",
                    "Experiment Tip: Test the timing and content to see which drives more engagement."
                ]
            },
            {
                title: "Repeat Purchases",
                content: [
                    "For loyal customers, deploy exclusive cohort-based discount popups integrated with GoKwik Checkout.",
                    "Experiment Tip: Monitor each cohort to assess repeat purchase uplift."
                ]
            }
        ]
    },
    {
        questNo:9,
        quest:"What are the pricing plans for KwikPass?",
        response:"Our pricing varies based on the features and usage. Please visit our pricing page or contact our sales team for a customized quote."
    },
    {
        questNo:10,
        quest:"Can we remove the KwikPass branding from the popups?",
        response:"KwikPass branding cannot be removed from login popup, but can be removed from marketing popup. However, you can customize other elements of the popups, such as colors, messaging, and design, to align with your brand identity."
    },
    {
        questNo:11,
        quest:"Is the spin wheel popup customizable?",
        response:"Absolutely! You can customize the design, discount values, and messaging on the spin wheel to align with your brand and promotional strategies."
    },
    {
        questNo:12,
        quest:"How do we target customers effectively?",
        response:"With KwikPass, you can segment customers based on what source they are coming from, past purchases, and engagement patterns. Using smart pop-ups on your website through different stages of their interaction you can target them at the right time with the right offers.Moreover, you can use KwikEngage to target them offline via WhatsApp basis their behavior."
    },

    
]

export const SectionSeven = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(prevIndex => prevIndex === index ? null : index);
    };

    return (
       <>
        <div className="container-fluid" style={{ marginTop: '80px'}}>
            <p style={{ 
                textAlign: 'center', 
                marginBottom: '10px'
            }}>FAQS</p>
            
            <div style={{ textAlign: 'center', color: 'black',marginBottom: '40px' }}>
                <h1 style={{ fontSize: '28px', marginBottom: '10px' }}>Frequently Asked Questions</h1>
            </div>
            
            <p style={{ 
                color: 'black', 
                textAlign: 'center', 
                fontSize: '16px',
           
            }}>
                Seamless, secure, and intelligent KwikPass integrates effortlessly with your ecosystem<br />
                to optimize user identification and engagement.
            </p>
            
            <div className='mt-3 mb-5'>
                <div style={{ 
                    display: 'flex', 
                    flexWrap: 'wrap', 
                    justifyContent: 'center', 
                    gap: '20px',
                    maxWidth: '1200px',
                    margin: '0 auto',
                    alignItems:'center'
                      
                }}>
                    {FAQData.map((faq, index) => (
                        <div 
                            key={faq.questNo} 
                            style={{ 
                                backgroundColor: '#FDA323', 
                                color: 'black', 
                                padding: '0 15px', 
                                borderRadius: '10px', 
                                width: '45%',
                                minWidth: '300px',
                                cursor: 'pointer',
                                boxSizing: 'border-box',
                                minHeight: activeIndex === index ? 'auto' : '50px',
                                height: activeIndex === index ? 'auto' : '50px',
                                overflow: 'hidden',
                                transition: 'height 0.3s ease'
                            }}
                        >
                            <div 
                                style={{ 
                                    display: 'flex', 
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    minHeight: '50px'
                                }} 
                                onClick={() => toggleAccordion(index)}
                            >
                                {activeIndex === index ? <IoIosArrowDown /> : <IoIosArrowUp />}
                                <h4 style={{ 
                                    fontSize: '18px', 
                                    fontWeight: 600, 
                                    margin: 0,
                                    paddingRight: '20px',
                                    paddingLeft: '20px',
                                    textAlign: "start"
                                }}>
                                    {faq.quest}
                                </h4>
                            </div>
                            
                            <div style={{ 
                                marginLeft: '35px',
                                opacity: activeIndex === index ? 1 : 0,
                                height: activeIndex === index ? 'auto' : 0,
                                overflow: 'hidden',
                                transition: 'opacity 0.3s ease'
                            }}>
                                {Array.isArray(faq.response) ? (
                                    faq.response.map((section, idx) => (
                                        <div key={idx} style={{ marginBottom: '15px' }}>
                                            <h5 style={{ 
                                                fontSize: '16px', 
                                                fontWeight: 'bold',
                                                marginBottom: '8px'
                                            }}>
                                                {section.title}
                                            </h5>
                                            <ul style={{ 
                                                margin: 0,
                                                paddingLeft: '20px'
                                            }}>
                                                {section.content.map((item, i) => (
                                                    <li key={i} style={{ 
                                                        marginBottom: '5px',
                                                        lineHeight: '1.4',
                                                    }}>
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))
                                ) : (
                                    <p style={{ 
                                        margin: 0,
                                        lineHeight: '1.5',
                                    }}>
                                        {faq.response}
                                    </p>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

        <div>
        <div style={{ textAlign: 'center', color: 'black' }}>
            <h1 style={{ fontSize: '28px', marginBottom: '10px' }}>Resources</h1>
            <div className="resources-sec">
              <div className="resources-card">
                <img src="/assets/images/kwikpass/resgroup1.png" alt="" />
                <p>Jimmy Luxury Takes A Fashionable Leap Online with GoKwik</p>
              </div>
              <div className="resources-card">
                <img src="/assets/images/kwikpass/resgroup2.png" alt="" />
                <p>Jimmy Luxury Takes A Fashionable Leap Online with GoKwik</p>
                {/* <p style={{color:"white"}}>Know More {">"} </p> */}
              </div>
              <div className="resources-card">
                <img src="/assets/images/kwikpass/resgroup3.png" alt="" />
                <p>Jimmy Luxury Takes A Fashionable Leap Online with GoKwik</p>
              </div>
            </div>
        </div>
        </div>
       </>
    );
}