import React from 'react'


const smartComm1 = [
    {
        img:"/assets/images/kwikpass/face-scanner.png",
        heading:"Advanced User Recognition",
        content:"Fingerprint tracking & intelligent identification."
    },
    {
        img:"/assets/images/kwikpass/database.png",
        heading:"Seamless Data Sync",
        content:"Direct integrations with leading CRM & marketing platforms."
    },
    {
        img:"/assets/images/kwikpass/stats.png",
        heading:"Analytics & Insights",
        content:"Google Analytics & Meta integration for deep performance tracking."
    },
    
]


const smartComm2 = [
    {
        img:"/assets/images/kwikpass/badge.png",
        heading:"Rewards & Loyalty",
        content:"Direct integrations with leading CRM & marketing platforms."
    },
    {
        img:"/assets/images/kwikpass/puzzle.png",
        heading:"Universal Compatibility",
        content:"Integrate with custom-built stores, Shopify & custom FE Shopify stores."
    },
  
]

export const SectionFive = () => {
  return (
                    <div>
                        <div className="container-fluid">
                            <div className="sec5-heading mt-5 mb-1">
                                <h1>Powering Smarter Commerce with Cutting-Edge Technology</h1>
                            </div>
                            <div className='d-flex justify-content-center sec5-para '>
                                <p>Seamless, secure, and intelligent—Kwik Pass integrates effortlessly with your ecosystem to optimize user identification and <br/> engagement.</p>
                            </div>
                            <div className='mx-4'>
                            <div className="container-fluid">
                <div className="row d-flex justify-content-start flex-wrap">
                    {smartComm1.map((item, index) => (
                        <div key={index} className="col-12 col-md-4 mb-4 SmartCard text-start">
                            <img src={item.img} alt={item.heading} className="mb-3" />
                            <h4 className="card-head">{item.heading}</h4>
                            <p>{item.content}</p>
                        </div>
                    ))}
                </div>
                </div>


                <div className="d-flex flex-column flex-md-row justify-content-center flex-nowrap mb-4">
                  {smartComm2.map((item, index) => (
                    <div key={index} className="SmartCard text-start mb-4 mb-md-0">
                      <img src={item.img} alt={item.heading} className="mb-3" />
                      <h4 className="card-head">{item.heading}</h4>
                      <p>{item.content}</p>
                    </div>
                  ))}
                </div>
            </div>
        </div>
    </div>
  )
}


