import React from "react";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const brands = [
  { src: "/assets/images/kwikpass/foxtale.png", alt: "Foxtale" },
  { src: "/assets/images/kwikpass/levis.png", alt: "Levis" },
  { src: "/assets/images/kwikpass/plix.png", alt: "Plix" },
  { src: "/assets/images/kwikpass/libas.png", alt: "Libas" },
  { src: "/assets/images/kwikpass/rare-rabbit.png", alt: "Rare Rabbit" },
  { src: "/assets/images/kwikpass/aqualogica.png", alt: "Aqualogica" },
  { src: "/assets/images/kwikpass/caffeine.png", alt: "Caffeine" },
  { src: "/assets/images/kwikpass/adilqadri.png", alt: "Adilqadri" },
  { src: "/assets/images/kwikpass/bombay.png", alt: "Bombay" },
  { src: "/assets/images/kwikpass/blissclub.png", alt: "Blissclub" },
  { src: "/assets/images/kwikpass/mivi.png", alt: "Mivi" },
  { src: "/assets/images/kwikpass/arata.png", alt: "Arata" },
  { src: "/assets/images/kwikpass/beco.png", alt: "Beco" },
  { src: "/assets/images/kwikpass/palm.png", alt: "Palm" },
  { src: "/assets/images/kwikpass/Ace.png", alt: "Ace" }
];




export const SectionOne = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const banners = [0, 1, 2]; // Representing your three banners


  // const [animate, setAnimate] = useState(false);
  // const [position, setPosition] = useState({ top: "50%", left: "45%" });

  // const [currentBanner, setCurrentBanner] = useState(2);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % banners.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="kwik-pass section-1">
        <div className="container-fuild">
          <div className="row banner">
            <div className="col-lg-6 banner-left">
              <div className="heading">
                <Fade direction="down">
                  <div className="first mb-3">
                    <p>KWIKPASS</p>
                  </div>
                  <h1>
                  Turn Every Visitor into a Paying Customer
                  </h1>
                  <p className="mt-3 second">
                  Effortlessly and securely identify visitors with phone-first logins and smart pop-ups.
                  Convert anonymous browsers into loyal customers and drive revenue with the most secure 
                 Shopify customer identification platform.
                  </p>
                </Fade>
                <Fade direction="right">
                  <div className="btn">
                    <a href="../contact">
                      <button className="cta">Book a Demo</button>
                    </a>
                    <a href="https://shopify.com">
                      <button className="cta2" style={{color:"#004B8D"}}>
                        {" "}
                        <img
                          src="/assets/images/kwikpass/shop.png"
                          alt="Shopify Logo"
                          width={20}
                        />
                        Get It On Shopify
                      </button>
                    </a>
                  </div>
                  <div className="mt-4" >
                      <a href="">
                        <img src="/assets/images/kwikpass/shopifybtn.png" alt="" />
                      </a>
                    </div>
                </Fade>
              </div>
            </div>
            <div className="col-lg-6 banner-right">
              <div className="banner-container" style={{ position: "relative", height: "60vh", width: "85vh" }}>
                <AnimatePresence mode="wait">
                  {banners.map((banner, index) => (
                    currentIndex === index && (
                      <motion.div
                        key={index}
                        className="banner-bg"
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 1}}
                        transition={{ duration: 0.5 }}
                      >
                        {index === 0 && (
                          <>
                            {/* Banner 1 */}
                            <div
                              className="circle-bg"
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "45%",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              <motion.img
                                src="/assets/images/kwikpass/banner1d.png"
                                alt="Banner Circle"
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 1, delay: 0.1, ease: "easeOut" }}
                                className="banner-img"
                                style={{ width: "150px", height: "auto" }}
                              />
                            </div>

                            <div className="banner-content">
                              <div className="side-images">
                                <motion.img
                                  src="/assets/images/kwikpass/banner1a.png"
                                  alt="Banner 1A"
                                  initial={{ opacity: 0, x: 100 }}
                                  animate={{ opacity: 1, x: 0 }}
                                  transition={{ duration: 1, delay: 0.3, ease: "easeOut" }}
                                  className="banner-img"
                                  style={{ width: "150px", height: "auto" }}
                                />
                                <motion.img
                                  src="/assets/images/kwikpass/banner1b.png"
                                  alt="Banner 1B"
                                  initial={{ opacity: 0, x: 100 }}
                                  animate={{ opacity: 1, x: 0 }}
                                  transition={{ duration: 1, delay: 0.4, ease: "easeOut" }}
                                  className="banner-img"
                                  style={{ width: "150px", height: "auto" }}
                                />
                              </div>

                              <div className="side-images">
                                <motion.img
                                  src="/assets/images/kwikpass/banner1c.png"
                                  alt="Banner 1C"
                                  initial={{ opacity: 0, x: 100 }}
                                  animate={{ opacity: 1, x: 0 }}
                                  transition={{ duration: 1, delay: 0.5, ease: "easeOut" }}
                                  className="banner-img"
                                 
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {index === 1 && (
                          <>
                            {/* Banner 2 */}
                            <motion.div
                              className="circle-bg"
                              style={{
                                position: "absolute",
                                top: "42%",
                                left: "58%",
                                transform: "translate(-50%, -50%)",
                              }}
                              initial={{ opacity: 0, scale: 0.8 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{ duration: 1, ease: "easeOut" }}
                            >
                              <motion.img
                                src="/assets/images/kwikpass/banner1d.png"
                                alt="Banner Circle 1"
                                className="banner-img"
                                style={{ width: "150px", height: "auto" }}
                              />
                            </motion.div>

                            <div className="banner-content2">
                              <motion.img
                                src="/assets/images/kwikpass/banner2a.png"
                                alt="Banner 1A"
                                initial={{ opacity: 0, x: 100 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1, delay: 0.3, ease: "easeOut" }}
                                className="banner-img img1"
                                style={{ width: "150px", height: "auto" }}
                              />
                              <motion.img
                                src="/assets/images/kwikpass/banner2b.png"
                                alt="Banner 1B"
                                initial={{ opacity: 0, x: 100 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1, delay: 0.4, ease: "easeOut" }}
                                className="banner-img img2"
                                style={{ height: "auto" }}
                              />

                              <div className="side-images">
                                <motion.img
                                  src="/assets/images/kwikpass/banner2d.png"
                                  alt="Banner 1C"
                                  initial={{ opacity: 0, x: 100 }}
                                  animate={{ opacity: 1, x: 0 }}
                                  transition={{ duration: 1, delay: 0.5, ease: "easeOut" }}
                                  className="banner-img img3"
                                  style={{ width: "150px"}}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {index === 2 && (
                          <>
                            {/* Banner 3 */}
                            <motion.div
                              className="circle-bg"
                              style={{
                                position: "absolute",
                                top: "42%",
                                left: "58%",
                                transform: "translate(-50%, -50%)",
                              }}
                              initial={{ opacity: 0, scale: 0.8 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{ duration: 1, ease: "easeOut" }}
                            >
                              <motion.img
                                src="/assets/images/kwikpass/banner3c.png"
                                alt="Banner Circle 1"
                                className="banner-img"
                                style={{ width: "150px", height: "auto" }}
                              />
                            </motion.div>

                            <div className="banner-content3">
                              <motion.img
                                src="/assets/images/kwikpass/banner3b.png"
                                alt="Banner 1B"
                                initial={{ opacity: 0, x: 100 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1, delay: 0.4, ease: "easeOut" }}
                                className="banner-img img1a"
                                style={{ height: "auto", width: "450px", marginTop: "100px" }}
                              />

                              <div className="side-images">
                                <motion.img
                                  src="/assets/images/kwikpass/banner3a.png"
                                  alt="Banner 1C"
                                  initial={{ opacity: 0, x: 100 }}
                                  animate={{ opacity: 1, x: 0 }}
                                  transition={{ duration: 1, delay: 0.5, ease: "easeOut" }}
                                  className="banner-img img3c"
                                  style={{ width: "150px" }}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </motion.div>
                    )
                  ))}
                </AnimatePresence>
              </div>
            </div>
          </div>
          <div className="banner-slider">
            <p className="slider-text">Trusted by 10,000+ Brands</p>
            <div className="slider-container">
              <div className="slider-track">
                {[...brands, ...brands].map((brand, index) => (
                  <img key={index} src={brand.src} alt={brand.alt} className="slider-img" />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};