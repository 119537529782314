import React from 'react'
import './styles/style.css'
import { SectionOne } from './parts/section-one'
import { SectionTwo } from './parts/section-two'
import { SectionThree } from './parts/section-three'
import {SectionFour} from './parts/section-four'
import { SectionFive } from './parts/section-five'
import { SectionSix } from './parts/section-six'
import { SectionSeven } from './parts/section-seven'

const KwikPass = () => {
  return (
    
    <div className="kwik-pass">
           <SectionOne  />
           <SectionTwo />
           <SectionThree />
           <SectionFour/>
           <SectionFive/>
           <SectionSix/>
           <SectionSeven/>
    </div>
    
  )
}

export default KwikPass
