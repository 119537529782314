import React from 'react'
import { Fade } from "react-awesome-reveal";

const TestimoData = [
    {
        img:"/assets/images/kwikpass/heal.png",
        state:"INDIA",
        content:"Great customer service and tech support from Kwikpass team now we are able to do nice process on our store.",
    },
    {
        img:"/assets/images/kwikpass/otrix.png",
        state:"INDIA",
        content:"Kwik Pass is incredibly supportive and always ready to help. Kriti Gautam is an excellent developer with great skills. They make a fantastic team! Also, Kiwi Pass is the best app for seamless login on Shopify.",
    },
    {
        img:"/assets/images/kwikpass/nisarg.png",
        state:"INDIA",
        content:"Using Kwik Pass has been an absolute delight! The app is simple to use and integrates perfectly with my Shopify store. What really stood out to me was their amazing support team—super friendly, quick to respond, and always ready to help whenever I needed it. Overall, it’s been a fantastic experience, and I’d highly recommend Kwik Pass to anyone looking to enhance their Shopify store. Definitely an app worth having!",
    }
]

export const SectionSix = () => {
  return (
    <div className='section-6'>
       <div className="container-fluid">
            <div className="sec-heading">
              <h1>Testimonials</h1>
            </div>
            <p style={{fontWeight:"100"}} className='mb-4'>What Our Merchants Say: Real Results, Real Growth</p>
            <div className="d-flex flex-wrap justify-content-center gap-3 overflow-x-auto">
                {TestimoData.map((item, index) => (
                    <div key={index} className="Testimo-Cards p-3 text-white d-flex flex-column align-items-center">
                        <div className="d-flex" style={{gap:"190px"}}>
                            <img 
                              src={item.img} 
                              alt="logo" 
                              className="me-2"
                              style={{ width: '80px', height: 'auto' }}  // Adjust image size for mobile
                            />
                            <p style={{ fontWeight: 100, opacity: "0.6", fontSize: '14px' }}>{item.state}</p>
                        </div>
                        <div className="mt-3 text-center" style={{ fontSize: '14px' }}>
                            {item.content}
                        </div>
                    </div>
                ))}
            </div>
            <Fade direction="down">
                <div className="last-section-six row d-flex align-items-center justify-content-between">
                    <div className="view col-lg-8 d-flex align-items-center">
                        <div className="view-img">
                            <img
                                src="/assets/images/kwikpass/view.png"
                                alt="Customer Engagement"
                                width={200}
                            />
                        </div>
                        <div className="view-text ms-4">
                            <h4>Ready to Transform Your Customer Engagement?</h4>
                            <p>
                                Top e-commerce brands use secure tracking tools to capture,
                                engage, and convert visitors. Integrate seamlessly and grow.
                            </p>
                        </div>
                    </div>
                    <div className="cta-container col-lg-4 text-end">
                        <button className="cta">Get Started Now</button>
                    </div>
                </div>
            </Fade>
        </div>
    </div>
  )
}
