import React, { useRef } from 'react';
import { FaCircleArrowLeft } from "react-icons/fa6";
import { FaCircleArrowRight } from "react-icons/fa6";
import { useMediaQuery } from 'react-responsive';

import Slider from "react-slick";


 export const SectionThree = () => {

const isMobile = useMediaQuery({ maxWidth: 768 });

const marketingDetail = [
  {
    heading:"A/B Testing",
    content:"Experiment with different  pop-up variations to see which drives the best results."
  },
  {
    heading:"Custom Fields",
    content:"Capture additional customer data, such as preferences or demographics, to personalize engagement."
  },
  {
    heading:"Redirection Links",
    content:"Redirect signed-in users to exclusive VIP pages or offers for higher engagement."
  }
]

const slides = [
  {
    title: 'Spin-the-Wheel',
    image: '/assets/images/kwikpass/market-carou1.png', // Replace with actual image URL
    heading: 'Gamified Engagement',
    description: 'Boost interaction with surprise rewards, perfect for engaging users who have visited multiple pages.',
  },
  {
    title: 'Discount Pop-Up',
    image: '/assets/images/kwikpass/market-carou2.png',
    heading: 'Timed Offers for Conversions',
    description: 'Display personalized discounts based on user behavior, triggered after a set time spent on the site.',
  },
  {
    title: 'Bottom Sheet Pop-Up',
    image: '/assets/images/kwikpass/market-carou3.png',
    heading: 'Subtle Lead Capture',
    description: 'Slides up from the bottom of the screen to capture leads without disrupting the user experience.',
  },
  {
    title: 'Limited Time Offer',
    image: '/assets/images/kwikpass/market-carou4.png',
    heading: 'Urgency-Based Discounts',
    description: 'Encourage quick decisions with limited-time discounts for visitors.',
  },
  {
    title: 'Exit Intent Pop-Up',
    image: '/assets/images/kwikpass/market-carou5.png',
    heading: 'Retain Leaving Users',
    description: 'Trigger discounts when users show exit intent to improve retention.',
  }
];


const settings = {
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Show 3 cards at a time
  slidesToScroll: 1, // Move 1 card at a time
  centerMode: false, // Ensure full visibility of cards
  arrows: false, // Show navigation arrows
  dots: false,  // Hide bottom navigation dots
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2, // Show 2 cards on medium screens
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1, // Show 1 card on mobile
        slidesToScroll: 1,
      },
    },
  ],
};
const settingsmob = {
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: "40px",
  arrows: false, // Hide default arrows
  dots: false,   // Hide navigation dots
  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: false,
      },
    },
  ],
};


let sliderRef = React.useRef(null);


// const scrollLeft = () => {
//   if (carouselRef.current) {
//     if (carouselRef.current.scrollLeft === 0) {
//       carouselRef.current.scrollLeft = carouselRef.current.scrollWidth / 2;
//     }
//     carouselRef.current.scrollLeft -= 320; // Adjust based on card width
//   }
// };

// const scrollRight = () => {
//   if (carouselRef.current) {
//     if (
//       carouselRef.current.scrollLeft + carouselRef.current.clientWidth >=
//       carouselRef.current.scrollWidth - 1
//     ) {
//       carouselRef.current.scrollLeft = carouselRef.current.scrollWidth / 2;
//     }
//     carouselRef.current.scrollLeft += 320;
//   }
// };

  return (
    <>
    <div className="section-three">
        <div className="container-fulid mt-4 ">
            <div className="faq-heading">
                <h1 style={{fontWeight:600}}>Marketing Pop-Ups</h1>
            </div>
            <div className='text-sm px-5 flex justify-content-center'>
                <p  style={{color:"black"}}>Marketing pop-ups are a game-changer for boosting customer engagement and conversions. With KwikPass, you <br/> get a range of smart, customizable pop-ups designed to capture leads and drive sales— <span style={{fontWeight:"600"}}>without additional costs!</span></p>
            </div>
            <div className="market-section">
              {marketingDetail.map((item, index) => (
                <div key={index} className="market-card">
                  <div className="headpass">{item.heading}</div>
                  <div className="contentpass">{item.content}</div>
                </div>
              ))}
           </div>

         {
          isMobile ? 
          <>
           <div className="carousel-container" style={{padding:0}}>
          <Slider ref={sliderRef} {...settingsmob}>
            {slides.map((slide, index) => (
              <div key={index} className="carousel-card">
                <h5 className="text-dark px-3" style={{fontWeight:"600"}}>{slide.title}</h5>
                <img src={slide.image} alt={slide.title} className="img-fluid my-3" style={{ borderRadius: "8px" }} />
                <h6 className="fw-semibold px-3">{slide.heading}</h6>
                <p className='px-3' style={{ fontSize: "14px"}}>{slide.description}</p>
              </div>
              
            ))}
          </Slider>
        </div>
        <div className="carousel-controls" >
        <div style={{ color: "#FFDDAD", opacity: "0.8" }} onClick={() => sliderRef.current.slickPrev()}>
           <FaCircleArrowLeft size={25} />
        </div>
        <div style={{ color: "#FFDDAD", opacity: "0.8" }} onClick={() => sliderRef.current.slickNext()}>
          <FaCircleArrowRight size={25} />
        </div>
            
          </div>
          
          </>
         
        : 
         <div className="carousel-container">
         <Slider ref={sliderRef} {...settings}>
           {slides.map((slide, index) => (
             <div key={index} className="carousel-card">
               <h5 className="mt-1 mb-3 px-3" style={{fontWeight:"600"}}>{slide.title}</h5>
               <img src={slide.image} alt={slide.title} className="img-fluid my-3" style={{ borderRadius: "8px" }} />
               <h6 className="fw-semibold px-3">{slide.heading}</h6>
               <p className='px-3' style={{ fontSize: "14px"}}>{slide.description}</p>
             </div>
           ))}
         </Slider>
       </div>
         }    
         
         <div className="carousel-controls mt-3" >
                <div style={{ color: "#FFDDAD", opacity: "0.8" }} onClick={() => sliderRef.current.slickPrev()}>
                   <FaCircleArrowLeft size={25} />
                </div>
                <div style={{ color: "#FFDDAD", opacity: "0.8" }} onClick={() => sliderRef.current.slickNext()}>
                  <FaCircleArrowRight size={25} />
                </div>
                    
                  </div>
      
    
        </div>
    </div>
    </>
  )
};
