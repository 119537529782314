import React, { useEffect, useRef, useState } from 'react'
import './style.css'

import SectionOne from './parts/section-one'
import SectionTwo from './parts/section-two'
import SectionFour from './parts/section-four'
import SectionThree from './parts/section-three'
import SectionFive from './parts/section-five'
import SectionSix from './parts/section-six'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import BannerOne from '@components/banner-one'
import data from "./data.json"
import { API_BASE_URL } from '@utils/constant'
import BannerHome from '@components/banner-home'
import { Helmet } from "react-helmet";


const Career = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})
	const [isLoadfadeSection, toggleFadeSection] = useState(false);



	async function fetchData() {
		fetch(API_BASE_URL + 'page/careers').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
		setTimeout(() => {
			toggleFadeSection(true);
		}, 3000);
	}, []);

	return (
		<>
			{/* <Helmet>
				<title>Careers | GoKwik</title>
				<meta name="description" content="Explore exciting career opportunities with GoKwik. Join our dynamic team dedicated to innovation and growth. Your future starts here. Apply now!"/>
			</Helmet> */}
			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'Careers | GoKwik'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : 'Explore exciting career opportunities with GoKwik. Join our dynamic team dedicated to innovation and growth. Your future starts here. Apply now!'}`} />
				{ 
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>
			<BannerHome arrow={false} data={result?.page?.banner}></BannerHome>
			{
				isLoadfadeSection &&
				<>
					<SectionOne data={result?.blocks?.block1}></SectionOne>

					<div className="career-section-two">
						<SectionTwo id="slider1" group={result?.groups?.block2} blocks={result?.blocks?.block2}></SectionTwo>
						{/* <SectionThree group={result?.groups?.block3} blocks={result?.blocks?.block3}></SectionThree> */}
						<SectionTwo id="slider2" group={result?.groups?.block4} blocks={result?.blocks?.block4}></SectionTwo>
					</div>



					<div className="career-section-four">
						{/* <SectionFour group={result?.groups?.block5} blocks={result?.blocks?.block5}></SectionFour> */}
						<SectionFive group={result?.groups?.block6} blocks={result?.blocks?.block6}></SectionFive>
						<SectionSix testimonials={result?.page?.testimonials} block={result?.blocks?.block7} careereye={result?.blocks?.block9}></SectionSix>
					</div>

					<SuccesStory customClass=""></SuccesStory>
					<SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>
				</>
			}

		</>
	)
}
export default Career